import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';

import 'isomorphic-unfetch';

const defaultCache: apiCache = {
  get(_key: string) {
    return undefined;
  },
  set(_key: string, _value: any, _time: number) {
    return;
  },
};

interface fetchWithRetryOptions extends RequestInit {
  retries?: number;
  retryDelay?: number; // in milliseconds
}
export interface PaginationRequest {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

/**
 * A wrapper around `fetch` with optional retries.
 * Retries up to `options.retries` times (defaults to `5` if not specified)
 * with a delay of `options.retryDelay` (defaults to `500ms` if not specified)
 */
const fetchWithRetry = async (url: string, options: fetchWithRetryOptions): Promise<Response> => {
  const retries = options.retries || 0;
  const retryDelay = options.retryDelay || 500;

  try {
    return await fetch(url, options);
  } catch (err) {
    if (retries < 1) throw err;

    // sleep the specified amount of time, then recursively retry
    await new Promise((r) => setTimeout(r, retryDelay));
    return await fetchWithRetry(url, { ...options, retries: retries - 1 });
  }
};

import * as Cookies from 'js-cookie';

import { RAILS_URL, XSRF_COOKIE_KEY, PARTNERSHIP_BFF_URL, ATHENA_URL } from '../globals';
import { globalObservability } from '@opendoor/observability/slim';
import { getAnonymousId } from '../shared/trackers';
import { apiCache } from '../../declarations/shared';
import { GraphQLClient } from 'graphql-request';
import {
  getSdk as getAthenaSdk,
  PageInput,
  PropertyFilters,
  OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product,
  GetSeveralFeatureFlagsRequestQueryVariables,
} from '../../__generated__/athena';
import { getSdk as getPartnershipsSdk } from '../../__generated__/partnerships';

import { getActiveSellDirectMarketOptions } from '../../helpers/markets';
import { getLocalFeatureFlagOverrides } from '../helpers/hooks';
import { GetServerSidePropsContext } from 'next';

const Sentry = globalObservability.getSentryClient();

interface IPostAddressOptions {
  agent_request?: boolean;
  channel?: string;
  covid19Whitelist?: boolean;
  experiment_entity_id?: string;
  manual?: boolean;
  seller_flow_name_override?: string;
  seller_flow_uuid_override?: string;
  trackingKeywords?: string;
}

// To do: strengthen jsonParam's type
// Do not pass skip doBFFPost as it will not actually skip making the request - instead handle it conditionally
export function doBFFPost(
  apiUrl: string,
  jsonParams: object,
  options: Omit<fetchWithRetryOptions, 'method'> = {},
): Promise<Response> {
  return fetchWithRetry(apiUrl, {
    retries: 5,
    retryDelay: 500,
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(jsonParams),
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get(XSRF_COOKIE_KEY) || '',
      ...options.headers,
    },
  });
}

export function doBFFPatch(
  apiUrl: string,
  jsonParams: object,
  options: Omit<fetchWithRetryOptions, 'method'> = {},
): Promise<Response> {
  return fetchWithRetry(apiUrl, {
    retries: 5,
    retryDelay: 500,
    credentials: 'include',
    method: 'PATCH',
    body: JSON.stringify(jsonParams),
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get(XSRF_COOKIE_KEY) || '',
      ...options.headers,
    },
  });
}

export function doBFFDelete(
  apiUrl: string,
  jsonParams: object,
  options: Omit<fetchWithRetryOptions, 'method'> = {},
): Promise<Response> {
  return fetchWithRetry(apiUrl, {
    retries: 5,
    retryDelay: 500,
    credentials: 'include',
    method: 'DELETE',
    body: JSON.stringify(jsonParams),
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get(XSRF_COOKIE_KEY) || '',
      ...options.headers,
    },
  });
}

export function getAthenaClient() {
  return getAthenaSdk(
    new GraphQLClient(ATHENA_URL, {
      credentials: 'include',
      headers: {
        'apollographql-client-name': 'cosmos',
      },
    }),
  );
}

function getPartnershipsClient() {
  return getPartnershipsSdk(
    new GraphQLClient(`${PARTNERSHIP_BFF_URL}/api/internal/graphql`, {
      credentials: 'include',
      headers: {
        'apollographql-client-name': 'cosmos',
      },
    }),
  );
}

export function doGet(
  path: string,
  options: Omit<fetchWithRetryOptions, 'body' | 'method'> = {},
): Promise<Response> {
  return fetchWithRetry(`${RAILS_URL}${path}`, {
    retries: 5,
    retryDelay: 500,
    credentials: 'include',
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Cookies.get(XSRF_COOKIE_KEY) || '',
      ...options.headers,
    },
  });
}

export function trackLead(trackingKeywords: string) {
  return doGet(`/track/${trackingKeywords}`);
}

type InfoResponse = {
  isLoggedIn: boolean;
  human?: {
    has_resale_closing_dashboard?: boolean;
  };
};
export async function getSessionExtras(): Promise<{ has_resale_closing_dashboard: boolean }> {
  try {
    const response = await doGet('/session/info');
    const data = (await response.json()) as InfoResponse;
    return { has_resale_closing_dashboard: data.human?.has_resale_closing_dashboard ?? false };
  } catch {
    return { has_resale_closing_dashboard: false };
  }
}

export function requestDevAPIAccess(
  humanId: number,
  name: string,
  email: string,
  company: string,
  blurb: string,
) {
  return doBFFPost(`${PARTNERSHIP_BFF_URL}/api/notification`, {
    humanId,
    name,
    email,
    company,
    blurb,
  }).then(handleResponse);
}

export async function validatePartnerEmailAddress(email: string) {
  try {
    return getPartnershipsClient().ValidateEmail({ email });
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}

export const createSellerInput = async (
  { street1, unit, city, postal_code, state }: IAddressInputAddress,
  {
    product,
    url,
  }: {
    product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
    url: string;
  },
  {
    channel,
    covid19Whitelist,
    experiment_entity_id: experimentEntityId,
    seller_flow_name_override: sellerFlowNameOverride,
    seller_flow_uuid_override: sellerFlowUuidOverride,
    trackingKeywords,
  }: IPostAddressOptions = {},
) => {
  if (trackingKeywords) {
    await trackLead(trackingKeywords).catch((e) => Sentry.captureException?.(e));
  }

  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'CreateSellerInputWithTrackingDataFromCookies',
      query: `mutation CreateSellerInputWithTrackingDataFromCookies(
        $input: OdProtosReceptionServices_CreateSellerInputRequestInput!
      ) {
        reception {
          createSellerInputWithTrackingDataFromCookies(input: $input) {
            sellerInput {
              uuid
              pdpUrl
              address {
                postalCode
              }
              analyticsMetadata {
                addressEntry {
                  product
                  url
                }
              }
            }
          }
        }
      }`,
      variables: {
        input: {
          address: {
            city,
            postalCode: postal_code,
            state,
            street: street1,
            unit,
          },
          channel,
          experimentEntityId,
          sellerFlowNameOverride,
          sellerFlowUuidOverride,
          covid19Whitelist,
          analyticsMetadata: {
            addressEntry: {
              product,
              url,
            },
          },
        },
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  ).then(handleResponse);
};

function handleResponse(res: Response) {
  if (res.ok) {
    return res.json();
  }
  Sentry.captureException?.(res);
  return;
}

const getGrainID = (customerUUID?: string, ssrCtx?: GetServerSidePropsContext) => {
  const anonymousId = getAnonymousId(ssrCtx);
  return {
    primaryId: { customerUuid: customerUUID ? customerUUID : null },
    temporaryId: { anonymousId: anonymousId ? anonymousId : null },
  };
};

export const getSeveralFeatureFlags = async (
  names: Record<string, { defaultValue: string }>,
  customerUUID?: string,
  options: { isLoading?: boolean; ssrCtx?: GetServerSidePropsContext; market?: string } = {},
) => {
  const { isLoading = false, ssrCtx, market = '' } = options;
  const customer = getGrainID(customerUUID, ssrCtx);
  const keys = Object.keys(names);
  const featureFlags: Partial<Record<string, string>> = {};
  if (isLoading) {
    keys.forEach((keyName) => {
      featureFlags[keyName] = names[keyName]?.defaultValue;
    });
  } else {
    const res = await doBFFPost(
      ATHENA_URL,
      {
        operationName: 'GetSeveralFeatureFlagsRequest',
        query: `query GetSeveralFeatureFlagsRequest(
            $grain: OdProtosVariantData_GrainInput
            $keys: [String!]
            $props: GoogleProtobuf_StructInput
          ) {
            variant {
              getFeatureFlags(input: { grain: $grain, keys: $keys, props: $props }) {
                variants {
                  key
                  value {
                    value
                  }
                }
              }
            }
          }`,
        variables: {
          grain: {
            grain: {
              customer,
            },
          },
          keys,
          props: {
            fields: [
              {
                key: 'market',
                value: { kind: { stringValue: market } },
              },
            ],
          },
        },
      },
      { headers: { 'apollographql-client-name': 'cosmos' } },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return;
      })
      .catch((e) => Sentry.captureException?.(e));

    if (res?.data?.variant?.getFeatureFlags?.variants) {
      res.data?.variant?.getFeatureFlags?.variants.forEach(
        (variant: { key: string; value: { value: string } | null }) => {
          const variantName = variant.key;
          const shouldOverrideLocally = getLocalFeatureFlagOverrides(variantName);
          if (shouldOverrideLocally) {
            featureFlags[variantName] = variantName;
          } else if (variant?.value?.value === '' || !variant?.value?.value) {
            featureFlags[variantName] = names[variantName]?.defaultValue;
          } else {
            featureFlags[variantName] = variant?.value?.value;
          }
        },
      );
    }
  }
  // returns an object of Feature Flags: if there is no value for the FF this returns the defaultValue
  return featureFlags;
};

export async function getFeatureFlag(
  name: string,
  customerUUID?: string,
  options: {
    isLoading?: boolean;
    autoImpression?: boolean;
    ssrCtx?: GetServerSidePropsContext;
    defaultValue?: string;
    props?: GetSeveralFeatureFlagsRequestQueryVariables['props'];
  } = {},
) {
  const { isLoading = false, ssrCtx, defaultValue = 'control', props } = options;
  const customer = getGrainID(customerUUID, ssrCtx);
  // Use local overrides if available
  const shouldOverrideLocally = getLocalFeatureFlagOverrides(name);
  if (shouldOverrideLocally) {
    // Returns true if override exists
    return Promise.resolve(shouldOverrideLocally);
  }
  if (isLoading) {
    return defaultValue;
  }
  const res = await doBFFPost(
    ATHENA_URL,
    {
      operationName: 'GetSeveralFeatureFlagsRequest',
      query: `query GetSeveralFeatureFlagsRequest(
          $grain: OdProtosVariantData_GrainInput
          $keys: [String!]
          $props: GoogleProtobuf_StructInput
        ) {
          variant {
            getFeatureFlags(input: { grain: $grain, keys: $keys, props: $props }) {
              variants {
                key
                value {
                  value
                }
              }
            }
          }
        }`,
      variables: {
        grain: {
          grain: customer,
        },
        keys: [name],
        props,
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return;
    })
    .catch((e) => Sentry.captureException?.(e));
  // Returns default if there is no value for the FF
  if (!res?.data?.variant?.getFeatureFlags?.variants[0].value?.value) {
    return defaultValue;
  }
  return res.data?.variant?.getFeatureFlags?.variants[0].value?.value;
}

export function getLatestLeadQuery(customerUUID: string, addressToken: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'GetLatestLeadQuery',
      query: `query GetLatestLeadQuery($addressToken: String!, $customerUUID: String!) {
        seller {
          getLatestLead(
            input: { addressUuid: $addressToken, customerUuid: $customerUUID }
          ) {
            lead {
              source {
                id
              }
            }
          }
        }
      }`,
      variables: {
        customerUUID,
        addressToken,
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export async function getPropertySchoolsQuery(latitude: number, longitude: number) {
  try {
    return getAthenaClient().GetNicheSchools({ latitude, longitude });
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}

export async function getBuyerAgentQuery(paramsId: string) {
  try {
    return getAthenaClient().GetAgentByParamsId({ paramsId });
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}

export async function getAgentRatingsQuery(agentId: string, paginationRequest?: PaginationRequest) {
  try {
    return getAthenaClient().GetAgentRatings({ agentId, paginationRequest });
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}

export async function getAgentProfileInfoById(agentId: string) {
  try {
    return getAthenaClient().GetAgentProfileInfo({ agentId });
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}

export function fetchAdressesByCustomerUUID(customerUUID: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'FetchAdressesQuery',
      query: `query FetchAdressesQuery($customerUUID: String!) {
        sellDirect {
          getAddressesByCustomerUUID(input: { customerUuid: $customerUUID }) {
            addressToken
          }
        }
      }`,
      variables: {
        customerUUID,
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export function fetchSellerReturnExperience(sellerInputUUID: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'ReturnExperienceQuery',
      query: `query ReturnExperienceQuery($sellerInputUUID: String!) {
      reception {
        getSellerInput(input: { uuid: $sellerInputUUID }) {
          sellerInput {
            productOfferings {
              deniedAt
            }
            address {
              street
            }
            answers {
              email
            }
          }
        }
      }
      sellDirect {
        getLead(input: { lead: { source: { type: RECEPTION, id: $sellerInputUUID }} }) {
          lead {
            isQualified
            customer {
              id
              name {
                givenName
              }
            }
            address {
              uuid
              street
            }
            latestOffer {
              preliminaryOfferExpirationDate
              state
              headlinePriceCents
              offerRequestSource
            }
            previousOffer {
              headlinePriceCents
            }
          }
        }
      }
    }`,
      variables: {
        sellerInputUUID,
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export function refreshOffer(sellerInputUUID: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'RequestRefreshOfferMutation',
      query: `mutation RequestRefreshOfferMutation($sellerInputUUID: String!) {
      seller {
        refreshOffersSingleLead(
          input: {
            leadId: { lead: { source: {type: RECEPTION, id: $sellerInputUUID} } }
            offerRequestSource: HOMEPAGE_RETURN_EXPERIENCE
          }
        ) {
          __typename
        }
      }
    }`,
      variables: {
        sellerInputUUID,
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export function standardizeAddress(address: IAddressInputAddress) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'StandardizeAddress',
      query: `query StandardizeAddress($address: OdProtosCasaServices_CasaRequest_FindByFieldsInput!) {
      casa {
        findByFields(input: $address) {
          address {
            uuid,
            street,
            city,
            state,
            unitNumber,
            unitType
            postalCode,
            latitude,
            longitude
          }
        }
      }
    }`,
      variables: {
        address: {
          city: address.city,
          postalCode: address.postal_code,
          state: address.state,
          street: address.street1,
          unitNumber: address.unit,
        },
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export function unsubscribeMailer(addressUuid: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'UnsubscribeMailer',
      query: `mutation UnsubscribeMailer($address: OdProtosWebServicesMarketing_UnsubscribeMailerRequestInput!) {
      marketing {
        unsubscribeMailer(input: $address) {
          _empty
        }
      }
    }`,
      variables: {
        address: {
          addressToken: addressUuid,
        },
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export function requestNewToken(expiredToken: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'RequestNewToken',
      query: `mutation RequestNewToken($expiredToken: String!) {
        passwordless {
          requestNewToken(input: {token: $expiredToken }) {
            token
          }
        }
      }`,
      variables: { expiredToken },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export function requestNewTokenByEmail(email: string, redirectUrl?: string) {
  return doBFFPost(
    ATHENA_URL,
    {
      operationName: 'RequestNewToken',
      query: `mutation RequestNewToken($email: String!, $redirectUrl: String) {
        passwordless {
          requestNewToken(input: {email: $email, redirectUrl: $redirectUrl}) {
            token
          }
        }
      }`,
      variables: { email, redirectUrl },
    },
    { headers: { 'apollographql-client-name': 'cosmos' } },
  )
    .then(handleResponse)
    .catch((e) => Sentry.captureException?.(e));
}

export async function fetchActiveMarkets(cache = defaultCache) {
  // we cache the results of this endpoint because it tends to time out
  let activeMarkets = cache.get('markets');

  if (activeMarkets === undefined) {
    const listMarketsResponse = await doBFFPost(
      ATHENA_URL,
      {
        operationName: 'ListMarkets',
        query: `query ListMarkets {
          webMarket {
            listMarkets(
              input:{}
            ) {
              market {
                id
                marketData {
                  marketIdentifier
                  active
                  displayName
                  launchDate
                  homesPath
                }
              }
            }
          }
        }`,
      },
      { headers: { 'apollographql-client-name': 'cosmos' } },
    );

    const data = await handleResponse(listMarketsResponse);

    const allMarkets = data.data.webMarket.listMarkets.market;

    activeMarkets = getActiveSellDirectMarketOptions(allMarkets);
    cache.set('markets', activeMarkets, 6 * 60 * 60); // 6 hours
  }

  return activeMarkets;
}

export async function getPropertiesByMarketQuery(
  shape: string,
  filters: PropertyFilters,
  pageInput: PageInput,
) {
  try {
    return await getAthenaClient().GetPropertiesByMarket({ shape, filters, pageInput });
  } catch (e) {
    Sentry.captureException?.(e);
    return;
  }
}
