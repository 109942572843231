export const US_STATE_CODES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const US_STATE_CODES_OPTIONS = US_STATE_CODES.map((c) => ({ value: c, label: c }));

export const MARKET_TO_STATE_ABBREV: { [key: string]: string } = {
  Asheville: 'NC',
  Atlanta: 'GA',
  Austin: 'TX',
  Bakersfield: 'CA',
  Birmingham: 'AL',
  Boise: 'ID',
  Charlotte: 'NC',
  Chattanooga: 'TN',
  Cincinnati: 'OH',
  Clarksville: 'TN',
  Cleveland: 'OH',
  'Colorado Springs': 'CO',
  Columbia: 'SC',
  Columbus: 'OH',
  'Corpus Christi': 'TX',
  Dallas: 'TX',
  Denver: 'CO',
  'Fort Collins': 'CO',
  Greensboro: 'NC',
  Greenville: 'SC',
  Houston: 'TX',
  Huntsville: 'AL',
  Indianapolis: 'IN',
  Jacksonville: 'FL',
  'Johnson City': 'TN',
  'Kansas City': 'KS',
  Killeen: 'TX',
  Knoxville: 'TN',
  'Las Vegas': 'NV',
  'Los Angeles': 'CA',
  Louisville: 'KY',
  Memphis: 'TN',
  Miami: 'FL',
  Minneapolis: 'MN',
  Nashville: 'TN',
  'New York New Jersey': 'NY',
  'Northern Colorado': 'CO',
  'Oklahoma City': 'OK',
  Orlando: 'FL',
  Phoenix: 'AZ',
  Portland: 'OR',
  Prescott: 'AZ',
  Raleigh: 'NC',
  Reno: 'NV',
  Riverside: 'CA',
  Sacramento: 'CA',
  'Salt Lake City': 'UT',
  'San Antonio': 'TX',
  'San Diego': 'CA',
  'Sf Bay Area': 'CA',
  Springfield: 'MO',
  'St Louis': 'MO',
  Tampa: 'FL',
  Tucson: 'AZ',
  Tulsa: 'OK',
  Unknown: '',
  'Washington Dc': 'D.C.',
  Wichita: 'KS',
};

export const getMarketDisplayNameWithState = (market: string | null) => {
  const edgeCase = handleEdgeCases(market || '');
  if (edgeCase) {
    return edgeCase;
  }

  let splits = (market as string).split('_');
  let stateAbbrev = '';
  // check if last element is a state code, remove it temporarily
  if (
    splits.length > 1 &&
    splits[splits.length - 1].length === 2 &&
    US_STATE_CODES.indexOf(splits[splits.length - 1].toUpperCase()) >= 0
  ) {
    stateAbbrev = splits[splits.length - 1];
    splits = splits.slice(0, splits.length - 1);
  }

  // Generate sentence casing and re-append the state code
  const lowerCaseSplits = splits.map((s) => s.toLowerCase());
  const sentenceCaseMarketName = lowerCaseSplits
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
  // Any markets launched after Q1 2022 will be systematically constructed e.g. Phoenix, AZ
  // The above constant (MARKET_TO_STATE_ABBREV) is a helper for markets created prior to this system,
  // and does not need to be used/updated for any future markets.
  stateAbbrev =
    stateAbbrev === ''
      ? (stateAbbrev = MARKET_TO_STATE_ABBREV[sentenceCaseMarketName])
      : stateAbbrev.toUpperCase();

  return sentenceCaseMarketName + ', ' + stateAbbrev;
};

function handleEdgeCases(market: string): string | undefined {
  if (market === 'clarksville_ky_tn') {
    return 'Clarksville, TN';
  }
  if (market === 'louisville_in_ky') {
    return 'Louisville, KY';
  }
  if (market === 'sf_bay_area') {
    return 'San Francisco Bay Area, CA';
  }
  if (market === 'new_york_new_jersey') {
    return 'New York / New Jersey';
  }
  if (market === 'washington_dc') {
    return 'Washington, D.C.';
  }
  if (market === 'southwest_fl') {
    return 'Southwest Florida';
  }
  return undefined;
}

export const alphabetize = (a: any, b: any): number => {
  return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
};

export const getActiveSellDirectMarketOptions = (markets: any) => {
  const activeSellDirectMarkets = markets.filter((market: any) => {
    if (market.marketData?.launchDate) {
      // returns seconds since epoch
      const now = Date.now();
      // returns seconds since epoch
      const launchDate = Date.parse(market.marketData?.launchDate);
      // Note: actual offset is +7 or +8 depending on daylight savings time
      // this doesn't have to be exact, so choosing +8
      const PST_OFFSET_MS = 8 * 60 * 60 * 1000;
      // only display the market is acive and the launch date has passed
      // add an offset, to standardize this check on PST
      return market.marketData?.active && launchDate + PST_OFFSET_MS <= now;
    }
  });

  const marketOptions = activeSellDirectMarkets.map((market: any) => {
    const identifier = market.marketData.marketIdentifier;
    const option = {
      label: getMarketDisplayNameWithState(identifier),
      value: market.marketData.homesPath,
    };
    return option;
  });

  return marketOptions.sort(alphabetize);
};
