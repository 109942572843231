import { useEffect, useState } from 'react';
import { getSeveralFeatureFlags, getFeatureFlag } from '../api';
import { GetServerSidePropsContext } from 'next';
import { GetSeveralFeatureFlagsRequestQueryVariables } from '__generated__/athena';

/**
 * Checks for a localstorage feature flag override
 * Used for development/QA only!
 *
 * force enabled -> window.localStorage.setItem('opendoor.reception.exclusive-offers', true)
 * force disabled -> window.localStorage.setItem('opendoor.reception.exclusive-offers', false)
 */
export const getLocalFeatureFlagOverrides = (featureFlag: string) => {
  if (
    // Ignore override check on the server
    typeof window !== 'undefined' &&
    window.localStorage.getItem(`opendoor.cosmos.${featureFlag}`) === 'true'
  ) {
    return true;
  }

  return false;
};

// Add Amplitude Feature Flags to this list to keep track of FFs in Cosmos: 'new-hub-nav' = 'new-hub-nav',
export enum AMPLITUDE_FEATURE_FLAGS {
  offerLockLanguage = 'seller-23-06-rollout-offer-lock-language',
}

// This pulls back an object of feature flags from amplitude - brings back to the default value if not found
export const useGetSeveralFeatureFlag = (
  names: Record<AMPLITUDE_FEATURE_FLAGS, { defaultValue: string }>,
  customerUUID?: string,
  options: { isLoading?: boolean; market?: string; ssrCtx?: GetServerSidePropsContext } = {},
) => {
  const [severalFeatureFlagsEnabled, setSeveralFeatureFlagsEnabled] = useState<
    Partial<Record<AMPLITUDE_FEATURE_FLAGS, string>>
  >({});

  useEffect(() => {
    getSeveralFeatureFlags(names, customerUUID, options).then(setSeveralFeatureFlagsEnabled);
  }, []);

  return severalFeatureFlagsEnabled;
};

export const useGetFeatureFlag = (
  name: string,
  customerUUID?: string,
  options: {
    isLoading?: boolean;
    autoImpression?: boolean;
    ssrCtx?: GetServerSidePropsContext;
    defaultValue?: string;
    props?: GetSeveralFeatureFlagsRequestQueryVariables['props'];
  } = {},
) => {
  const [featureFlag, setFeatureFlag] = useState<string>('');

  useEffect(() => {
    getFeatureFlag(name, customerUUID, options).then(setFeatureFlag);
  }, []);

  return featureFlag;
};
