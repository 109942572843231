// storybook-check-ignore
import * as React from 'react';
import { trackImpression, ImpressionPayload, trackInteraction } from '@opendoor/track/lib/tracker';
import { useInView, InViewHookResponse } from 'react-intersection-observer';
import * as Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import { GetServerSidePropsContext } from 'next';

export const normalize = (input: string): string => {
  return input
    .split(/\s+/)
    .map((s) => s.toLowerCase())
    .join('-');
};

type RefResponse = InViewHookResponse[0];
export const useClickTracking = (
  trackProps: ImpressionPayload,
): [RefResponse, (extra?: any) => void] => {
  const [ref, inView, event] = useInView();
  const interactionIdRef = React.useRef<string | null>(null);
  React.useEffect(() => {
    // the intersection observer sends an initial "inView" request
    // with a null event. This one isn't an impression, seems to
    // just be an odd side-effect (or maybe implementation bug).
    // so we ignore anything with an undefined event.
    if (!event) {
      return;
    }
    if (inView) {
      interactionIdRef.current = trackImpression(trackProps).impressionId;
      return;
    }
    // if we're not inView, and had an observer event, then reset the
    // interaction id.
    interactionIdRef.current = null;
  }, [inView, event]);
  const recordInteraction = React.useCallback(
    (extra?: any) => {
      trackInteraction({
        impressionId: interactionIdRef.current || 'no-impression',
        type: 'click',
        extra,
      });
    },
    [interactionIdRef],
  );
  return [ref, recordInteraction];
};

export const useTrackableLink = (
  href: string,
  location: string,
  elementName?: string,
  moveUrlFn?: (href: string) => void,
): [RefResponse, (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void] => {
  const [ref, recordInteraction] = useClickTracking({
    elementId: `link-to:${elementName || href}`,
    interactable: true,
    location,
    type: 'ready',
  });
  // record the interaction, then navigate to the link
  const recordClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      // record the href as an extra field to query on
      recordInteraction({ href: href });
      // 300ms is the timeout segment uses in "trackLink" to give the library
      // time to record the interaction before navigating.
      setTimeout(() => {
        // default is location.assign, but can be customized, for
        // e.g. react-router
        moveUrlFn ? moveUrlFn(href) : window.location.assign(href);
      }, 300);
    },
    [recordInteraction],
  );
  return [ref, recordClick];
};

export function getAnonymousId(ssrCtx?: GetServerSidePropsContext): string | undefined {
  let anonymousId = ssrCtx
    ? ssrCtx.req.cookies['ajs_anonymous_id']
    : Cookies.get('ajs_anonymous_id');
  if (!anonymousId) {
    return undefined;
  }
  // in prod, ajs_anonymous_id starts and ends with ", but in staging, it does not
  // it's unclear why, but we should handle both cases
  if (anonymousId.startsWith('"') && anonymousId.endsWith('"')) {
    anonymousId = anonymousId.slice(1, -1);
    // This is temporary - we will delete this out once we see that no users need to re-format their anonymousId
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/upgrade-to-ajs2/#relying-on-analyticsjs-classics-ajs_anonymous_id-cookie-format
    datadogRum.addError(
      new Error(
        'anonymousId needs to be reformatted which means we are still using analytics classic',
      ),
      {
        data: {
          location: 'cosmos',
          anonymousId: anonymousId,
        },
        label:
          'anonymousId needs to be reformatted which means we are still using analytics classic',
      },
    );
  }
  return anonymousId;
}

export const setCookie = (key: string, value: string, expires: number) => {
  // For real staging and production, we need the cookie to be accessible
  // across all subdomains
  // i.e. ".domain.com" cookies are available on all subdomains, vs "www.domain.com"
  // which is only available on same-origin
  // https://www.mxsasha.eu/blog/2014/03/04/definitive-guide-to-cookie-domains/
  let cookieDomain = window.location.hostname;
  if (cookieDomain.indexOf('opendoor.com') !== -1) {
    cookieDomain = '.opendoor.com';
  } else if (window.location.hostname.indexOf('simplersell.com') !== -1) {
    cookieDomain = '.simplersell.com';
  }
  // expires is in days
  Cookies.set(key, value, { expires, domain: cookieDomain });
};
